html, body
{
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
     
        margin: 0;
        padding: 0;
        height: 100vh;
        width: 100vw; 

        background-color: #000;  
}

.app
{
    /* background-color: #000;   */
}