/* https://codepen.io/alphardex/pen/QWwveZG */


@import url("https://fonts.googleapis.com/css?family=Raleway:400,400i,700");

.layout-body
{
    width: 100vw;

    padding-left: 3vw;
    padding-top: 5vh;
    padding-right: 5vw;
    padding-bottom: 5vh;
    
    /* background-color: #060506; */
    /* display: inline-block; */

}

.layout-body h2
{
    color:  #198CE6;
}

.layout-body p
{
    color:  #198CE6;
}