/* https://codepen.io/alphardex/pen/QWwveZG */

@import url("https://fonts.googleapis.com/css?family=Raleway:400,400i,700");

.layout-nav-bar
{
    height: 10vh;
    display: flex;
    text-decoration: none;
    justify-content: space-between;

    align-items:center;

    background-color: black;

    padding: 0;
}

 .layout-nav-bar *
{
    text-decoration: none;
    list-style-type:  none;
    padding: 0;
}


.nav-home
{
    padding: 0;
 
    padding-left: 1vw;

 
}

.nav-item
{
  
    padding-right: 1vw;
}

.nav-links
{
    display:flex;
}

/* .layout-nav-bar li
{
    list-style-type:  none;
} */

.layout-nav-bar p
{
    --fill-color: #198CE6;   

    font: 700 2rem Raleway, sans-serif;

    text-decoration: none;
    text-transform: uppercase;
    
    background: linear-gradient( var(--fill-color) 0 100%) left / 0 no-repeat;
    -webkit-text-stroke: 2px var(--fill-color);
    
    color: transparent;
    
    background-clip: text;
    -webkit-background-clip: text;

    transition: 0.25s linear;

   
    
    
    &:hover 
    {
      background-size: 100%;
    }
}


